import React, { useContext, useEffect, useState } from 'react'
import NorthCapitalContext from '../../../../product/context/payment/northcapital/northcapitalContext'
import AuthContext from '../../../../product/context/auth/authContext'
import Popup from '../../organisms/Popup'
import AlertContext from '../../../../product/context/alert/alertContext'

function NorthCapitalStripeCardDetails(props) {
    const authContext = useContext(AuthContext)
    const { user, isAuthenticated } = authContext
    const { linkCreditCard, north_capital_card_link, deleteCreditCard } = useContext(
        NorthCapitalContext,
    )

    const [cardLink, setCardLink] = useState(null)

    // const [message, setMessage] = useState('')

    // This hook is listening an event that came from the Iframe
    // useEffect(() => {
    //     const handler = (ev: MessageEvent<{ type: string, message: string }>) => {
    //         if (ev.origin === 'https://api-sandboxdash.norcapsecurities.com') {
    //             console.log('ev Origin ', JSON.parse(ev.data))
    //             let responseData = JSON.parse(ev.data)
    //             if (responseData.statusCode === 101) {
    //                 setAlert('Card Successfully added', 'success')
    //                 props.function(false, 'new', null)
    //             } else {
    //                 setAlert(responseData.accountDetails, 'error')
    //             }
    //         }
    //     }

    //     window.addEventListener('message', handler)

    //     // Don't forget to remove addEventListener
    //     return () => window.removeEventListener('message', handler)
    // }, [])

    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext

    useEffect(() => {
        if (props.data.data) {
            if (props.data.status === 'delete') {
                deleteCreditCard()
            }
        }
    }, [props.data.data])

    useEffect(() => {
        if (isAuthenticated) {
            if (
                props.data.popup &&
                (props.data.status === 'new' || props.data.status === 'update')
            ) {
                linkCreditCard()
            }
        }
    }, [isAuthenticated, props.data])

    useEffect(() => {
        console.log('north_capital_card_link', north_capital_card_link)
        if (north_capital_card_link) {
            setCardLink(north_capital_card_link)
        }
    }, [north_capital_card_link])

    return (
        <>
            <Popup
                open={
                    props.data.popup &&
                    (props.data.status === 'new' || props.data.status === 'update')
                }
                size="sm"
                handleClose={() => props.function(false, 'new', null)}
                modaltitle="Add Card Details"
                className="iframeModal"
            >
                <div className="cardDetails">
                    {/* {localStorage.ttw_temp_user_id ? ( */}
                    <p className="textHeader">
                        <b>Note: </b>To validate your identity to bid, we need you to add a valid
                        Credit Card. You will not be charged for making a VIP Reservation.
                    </p>
                    {/* ) : null} */}
                    {cardLink ? <iframe src={cardLink} /> : null}
                </div>
            </Popup>
        </>
    )
}

export default NorthCapitalStripeCardDetails
